import { Routes } from '@angular/router';

export const rootRouterConfig: Routes = [
  {
    path: '*',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: './layouts/layout.module#LayoutModule'
  }
  // {
  //   path: '',
  //   redirectTo: 'demos',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'demos',
  //   loadChildren: './main/main.module#DemoModule',
  // },
  // {
  //   path: '**',
  //   redirectTo: 'layout/one',
  // },
];
